export enum AmlSourceOfIncome {
  Employment = 1,
  Enterpreneurship = 2,
  Retirement = 3,
  PropertyOwnership = 4,
  Other = 5,
  MoneyFromClosePerson = 6,
  Heritage = 8,
  Scholarship = 9,
  Gift = 10,
  AlimonyChildSupport = 11,
  Savings = 12,
  Investments = 13,
}
